import axios from "axios";
import {eventBus} from "@/eventBus";
import {toast} from "vue3-toastify";

const authToken = document.querySelector("meta[name=\"auth-token\"]").getAttribute("content");
const headers = {};
if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
}

const authInstance = axios.create({
    headers
});

const apiInstance = axios.create({
    baseURL: "/api",
    headers
});

const handleAuthErrors = (error, setErrors) => {
    if (error.response) {
        if (error.response.status === 422 && setErrors) {
            console.log("setErrors");
            setErrors(error.response.data.errors);
        } else {
            console.error(
                "An unexpected error occurred:",
                error.response.data.message || "Unknown error",
            );
        }
    } else {
        console.error(error);
    }
};

export const createCancelToken = (callback) => {
    return new axios.CancelToken(callback);
};

export const handleAuthFormSubmit = async ({
                                               url,
                                               values,
                                               setErrors,
                                               dbToken,
                                           }) => {
    if (dbToken) {
        values.db_token = dbToken;
    }

    try {
        const response = await authInstance.post(url, values);

        if (response.data.redirect) {
            window.location.href = response.data.redirect;
        }
    } catch (error) {
        handleAuthErrors(error, setErrors);
    }
};

export const handleGlobalSearch = async ({query, section, cancelToken}) => {
    try {
        const response = await apiInstance.get("/global-search", {
            cancelToken,
            params: {query, section},
        });

        return Object.values(response.data).map((item) => ({
            group_title: item.group_title,
            items: item.items.map((child) => ({
                url: child.url,
                title: child.data.title,
                content: child.data.content,
            })),
        }));
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log("Search request cancelled");
        } else {
            console.error("Search error:", error);
        }
    }
};

export const handleGetSimple = async (path, {cancelToken, params} = {}) => {
    try {
        const response = await apiInstance.get(path, {
            ...(cancelToken && {cancelToken}),
            params
        });

        return response.data;
    } catch (error) {
        console.error("Get request error:", error);
    }
};

export const handlePostSimple = async (path, params = {}) => {
    try {
        const response = await apiInstance.post(path, params);

        return response.data;
    } catch (error) {
        console.error("Post request error:", error);
    }
};

export const handlePutSimple = async (path, params = {}) => {
    try {
        const response = await apiInstance.put(path, params);

        return response.data;
    } catch (error) {
        console.error("Put request error:", error);
    }
};

export const handleGetWithParams = async ({path, params, cancelToken}) => {
    try {
        const response = await apiInstance.get(path, {
            cancelToken,
            params,
        });

        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log("Search request cancelled");
        } else {
            console.error("Search error:", error);
        }
    }
};

export const handleMassUpdate = async (endpoint, params) => {
    try {
        const response = await apiInstance.post(
            `${endpoint}/mass-update`,
            params,
        );

        return response.data;
    } catch (error) {
        console.error(" Mass update error:", error);
    }
};

export const handleMassDelete = async (endpoint, params) => {
    try {
        const response = await apiInstance.post(
            `${endpoint}/mass-delete`,
            params,
        );

        return response.data;
    } catch (error) {
        console.error("Mass delete error:", error);
        throw error;
    }
};

export const handleDelete = async (path) => {
    try {
        await apiInstance.delete(path);
    } catch (error) {
        console.error("Delete error:", error);
    }
};

export const handleAutocompleteSearch = async ({
                                                   service_name,
                                                   query,
                                                   relative_id,
                                                   cancelToken,
                                               }) => {
    try {
        const response = await apiInstance.get("/search-autocomplete", {
            cancelToken,
            params: {service_name, query, relative_id},
        });

        const searchResult = response.data.map((item) => ({
            value: item.id,
            text: item.name,
            other: item.other
        }));

        return searchResult;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log("Search request cancelled");
        } else {
            console.error("Search error:", error);
        }
    }
};

export const handleConstructorFormSubmit = async ({
                                                      method,
                                                      url,
                                                      values,
                                                      clearValue,
                                                      setErrors,
                                                      callbackAfterSubmitted
                                                  }) => {
    try {
        const response = await authInstance({
            method,
            url,
            data: values,
        });

        if (response.data.redirect_url) {
            eventBus.confirmLeavePage = false;

            setTimeout(() => {
                window.location.href = response.data.redirect_url;
            }, 100);

            return;
        }

        if (callbackAfterSubmitted && typeof callbackAfterSubmitted === "function") {
            callbackAfterSubmitted(clearValue);
        }

        toast("The data was saved successfully.", {
            position: "bottom-right",
            theme: "colored",
            type: "success",
            transition: "flip"
        });
    } catch (error) {
        console.log("Errors", error);
        handleAuthErrors(error, setErrors);
    }
};
